import React from 'react';
import { useApolloClient } from '@apollo/client';
import {
  FileSolidIcon,
  Greyscale,
  PowerIcon,
  SettingSolidIcon,
  TicketSolidIcon,
  UserIcon,
} from 'glints-aries';
import { useRouter } from 'next/router';
import Link from 'nextDir/modules/Router/Link';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { useGetMarketingQueriesFromRoute } from 'src/common/links/hooks/useGetMarketingQueriesFromRoute';
import { ROUTES } from 'src/common/routes';
import { getIsExpertClassOrdersEnabled } from 'src/modules/ExpertClassOrder/Selectors';
import { settingPageLinkMessage } from 'src/modules/MainContainer/Navbar/messages';
import { logout } from 'src/modules/Session/Actions';
import { getUser } from 'src/selectors/user';

import {
  Dropdown,
  DropdownWrapper,
  NameHolder,
  ProfilePicture,
  UserMenuContainer,
  UserSettingSection,
} from './UserMenuComponents.sc';

const UserMenu: React.FC = () => {
  const router = useRouter();
  const marketingQueryUtmParams = useGetMarketingQueriesFromRoute();

  const dispatch = useDispatch();
  const apolloClient = useApolloClient();

  const me = useSelector(getUser);
  const isExpertClassOrdersEnabled = useSelector(getIsExpertClassOrdersEnabled);

  const handleLogout = () => {
    dispatch(logout(apolloClient));
  };

  const renderDropDown = (itemElement: React.ReactNode) => {
    const fullName = `${me.firstName || ''} ${me.lastName || ''}`.trim();

    return (
      <Dropdown
        aria-label="User menu"
        showHoverLine={true}
        dropDownPlacement="right"
        label={fullName}
        itemElement={itemElement}
        key={fullName}
      >
        <Dropdown.Item
          key="profile"
          onClick={() =>
            router.push({
              pathname: `/${ROUTES.profile}`,
              query: marketingQueryUtmParams,
            })
          }
        >
          <DropdownWrapper>
            <UserIcon color={Greyscale.black} />
            <FormattedMessage
              id="my.profile"
              defaultMessage="My Profile"
              tagName="span"
            />
          </DropdownWrapper>
        </Dropdown.Item>

        <Dropdown.Item
          key="my-applications"
          onClick={() =>
            router.push({
              pathname: `/${ROUTES.applications}`,
              query: marketingQueryUtmParams,
            })
          }
        >
          <DropdownWrapper>
            <FileSolidIcon color={Greyscale.black} />
            <FormattedMessage
              id="my.applications"
              defaultMessage="My Applications"
              tagName="span"
            />
          </DropdownWrapper>
        </Dropdown.Item>

        {isExpertClassOrdersEnabled && (
          <Dropdown.Item
            key="my-tickets"
            onClick={() =>
              router.push({
                pathname: `/${ROUTES.expertClassOrders}`,
                query: marketingQueryUtmParams,
              })
            }
          >
            <DropdownWrapper>
              <TicketSolidIcon color={Greyscale.black} />
              <FormattedMessage
                id="user-menu.expert-class-ticket"
                defaultMessage="My Tickets"
              />
            </DropdownWrapper>
          </Dropdown.Item>
        )}

        <Dropdown.Item
          key="setting"
          onClick={() =>
            router.push({
              pathname: `/${ROUTES.settings}`,
              query: marketingQueryUtmParams,
            })
          }
        >
          <DropdownWrapper>
            <SettingSolidIcon color={Greyscale.black} />
            <FormattedMessage {...settingPageLinkMessage} />
          </DropdownWrapper>
        </Dropdown.Item>

        <Dropdown.Item key="log-out" onClick={handleLogout}>
          <DropdownWrapper>
            <PowerIcon color={Greyscale.black} />
            <FormattedMessage
              id="user.menu.sign.out"
              defaultMessage="Sign Out"
              tagName="span"
            />
          </DropdownWrapper>
        </Dropdown.Item>
      </Dropdown>
    );
  };

  const renderUserSettingSection = () => {
    return (
      <UserSettingSection>
        <Choose>
          <When condition={me.firstName || me.lastName}>
            {renderDropDown(
              <NameHolder>{me.firstName || me.lastName}</NameHolder>
            )}
          </When>
          <Otherwise>
            {renderDropDown(
              <NameHolder>
                <FormattedMessage
                  id="user.new"
                  defaultMessage="User"
                  tagName="span"
                />
              </NameHolder>
            )}
          </Otherwise>
        </Choose>
      </UserSettingSection>
    );
  };

  return (
    <UserMenuContainer>
      <Link href="/profile">
        <a>
          <ProfilePicture
            profilePic={me.profilePic}
            userID={me.id}
            alt="Profile Picture"
            sizes="40px"
          />
        </a>
      </Link>
      {renderUserSettingSection()}
    </UserMenuContainer>
  );
};

export default UserMenu;
